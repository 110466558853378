import Observer from "../observerstore/Observer";
import React from "react";
import ParentProduct from "../models/ParentProduct";
import Price from "../interfaces/Price";
import PRICECATEGORIES from "../constants/priceCategories";

interface Props {
	product: ParentProduct;
}
interface State {
	price: boolean;
	detail: boolean;
}

export default class PricesVarieties extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			price: false,
			detail: false,
		};
		this.click = this.click.bind(this);
	}

	click(e: any) {
		console.log(e.target.id);
		this.setState({
			price: !this.state.price,
		});
	}

	mouseEnter(productId: number) {
		console.log(productId);
	}

	mouseLeave() {
		console.log("verlassen");
	}

	renderPrice<K extends keyof Price>(input: K): JSX.Element {
		let price: string = this.props.product.getPrice(input);

		return (
			<React.Fragment>
				{price !== "– –" && price !== "LP" && (
					<span className="text-left">CHF</span>
				)}
				<span className="float-right">{price}</span>
			</React.Fragment>
		);
	}

	render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>
				<table className="prices-table">
					<tbody>
						{PRICECATEGORIES.map(
							(p: any, i: number) =>
								this.props.product.getPrice(p.price) !==
									"– –" && (
									<tr key={i}>
										<th className="text-right">
											{p.pictures.map(
												(picture: any, z: number) => (
													<img
														key={z}
														alt={picture.alt}
														src={picture.src}
														className="pictogram"
													/>
												)
											)}
										</th>
										<td>{this.renderPrice(p.price)}</td>
									</tr>
								)
						)}
						<tr
							onMouseEnter={() =>
								this.mouseEnter(this.props.product.id)
							}
							onMouseLeave={this.mouseLeave}
						></tr>
					</tbody>
				</table>
			</React.Fragment>
		);
	}
}
