import { REQUESTURL } from "../constants/urls";
import Group from "../models/Group";

export default class GroupCollection {
	collection: Group[];
	count: number;
	localStorageKey: string;

	constructor() {
		this.collection = [];
		this.count = 0;
		this.localStorageKey = "token";
	}

	readApi(
		Store: any,
		categories: number[],
		anzahl: number,
		seite: number,
		sortAttribute: string,
		sortOrder: string,
		searchTerm: string,
		searchType: string,
	) {
		if(searchTerm === '') searchTerm = '0';
		if(searchType === 'number') searchType = 'nummer';
		if(searchType === 'all') searchType = '0';
		if(searchType === 'year') searchType = 'year';
		const _this = this;
		const url = `${REQUESTURL}productcollection`;

		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json"
			},
			body: JSON.stringify({
				searchTerm: searchTerm,
				searchType: searchType,
				kat_id: categories,
				seite: seite,
				anzahl: anzahl,
				sortcolumn: sortAttribute,
				sortorder: sortOrder,
			})
		})
			.then(function(response) {
				if (response.ok) return response.json();
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function(json) {
				_this.translateApi(json);
				Store.notify();
			})
			.catch(function(err) {
				console.error(err);
			});
	}

	translateApi(json: any) {
		this.collection = json.products.map((g: any) => {
			let group: Group = new Group();
			group.translateApi(g);
			return group;
		});
		this.count = Number(json.count);
	}

}
