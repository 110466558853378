// import Product from "./Product";
export default class Category {
	id: number;
	sort: number;
	level_1: string;
	level_2: string;
	level_3: string;
	level_4: string;
	hauser_zusatz: string;
	involvedIds: string[];

	constructor() {
		this.id = 0;
		this.sort = 0;
		this.level_1 = "";
		this.level_2 = "";
		this.level_3 = "";
		this.level_4 = "";
		this.hauser_zusatz = "";
		this.involvedIds = [];
	}

	translateApi(json: any): void {
		this.id = Number(json.kat_id);
		this.sort = json.kat_sort;
		this.level_1 = json.kat_level_1;
		this.level_2 = json.kat_level_2;
		this.level_3 = json.kat_level_3;
		this.level_4 = json.kat_level_4;
		this.hauser_zusatz = json.pro_hauser_zusatz;
		this.involvedIds.push(json.kat_id);
	}
}
