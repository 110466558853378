import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as T from "../language/UnderConstruction.json";
import { Language } from "../types/Language";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	language: Language;
}

export default class UnderConstruction extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			language: $.language,
		};
	}

	public componentDidMount(): void {
		Store.attach(this);
		// Beim Reloading erscheint sonst immer deutsch
		this.update();
	}

	public update(): void {
		this.setStateNStore({
			language: $.language,
		});
	}

	public render(): React.ReactNode {
		return (
			<>
				<Container>
					<code className="component_info">
						{this.constructor.name}
					</code>
					<Row>
						<Col sm={9}>
							<div className="main">
								<h1>{T.vorbereitung[this.state.language]}</h1>
							</div>
						</Col>
						<Col sm={3}></Col>
					</Row>
				</Container>
			</>
		);
	}
}
