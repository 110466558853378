import React from "react";
import Col from "react-bootstrap/Col";
import AdditionalInformations from "../components/AdditionalInformations";
import Row from "react-bootstrap/Row";
import Picture from "../interfaces/Picture";
import Color from "../models/Color";
import PricesVarieties from "../components/PricesVarieties";
import Observer from "../observerstore/Observer";
import { Language } from "../types/Language";
import ImageGallery from "react-image-gallery";
import Store from "../observerstore/Store";
const $ = Store.data;

interface ImageGalleryItem {
	original: string;
	thumbnail: string;
	originalAlt: string;
	thumbnailAlt: string;
	originalTitle: string;
	thumbnailTitle: string;
}

interface Props {
	color: Color;
}

interface State {
	bilder: Picture[];
	language: Language;
}

export default class ColorDetail extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			bilder: this.props.color.color_pictures,
			language: $.language,
		};
	}

	private imagesVerarbeiten(color: Color): ImageGalleryItem[] {
		const images: ImageGalleryItem[] = [];
		for (let v of color.color_pictures) {
			images.push({
				original: v.large,
				thumbnail: v.thumbnail,
				originalAlt: `Briefmarke: ${color.getName(
					this.state.language
				)}`,
				thumbnailAlt: `Briefmarke: ${color.getName(
					this.state.language
				)}`,
				originalTitle: color.getName(this.state.language),
				thumbnailTitle: color.getName(this.state.language),
			});
		}
		return images;
	}

	public update() {
		this.setStateNStore({
			language: $.language,
		});
	}

	private renderSpecialInfos(): React.ReactNode {
		let ausgabetag: string = this.props.color.ausgabetag;
		let druckauflage: string = this.props.color.druckauflage;

		if (ausgabetag !== "" && druckauflage !== null) {
			return (
				<div>
					{"Ausgabedatum: " + ausgabetag}
					<br />
					{"Druckauflage ca. " + druckauflage}
					<hr className="zero-line" />
				</div>
			);
		} else if (ausgabetag !== "" && druckauflage === null) {
			return (
				<div>
					{"Ausgabedatum: " + ausgabetag}
					<hr className="zero-line" />
				</div>
			);
		} else if (ausgabetag === "" && druckauflage !== null) {
			return (
				<div>
					{"Druckauflage ca. " + druckauflage}
					<hr className="zero-line" />
				</div>
			);
		}
	}

	private renderPrices(): React.ReactNode {
		return <PricesVarieties product={this.props.color} />;
	}

	public render(): React.ReactNode {
		return (
			<>
				<article
					style={{
						border: "1px solid #ac9259",
						padding: "10px",
					}}
				>
					<code className="component_info">
						{this.constructor.name}
					</code>
					<Row>
						<Col sm={6} className="detail">
							{this.props.color !== undefined && (
								<ImageGallery
									items={this.imagesVerarbeiten(
										this.props.color
									)}
									showPlayButton={false}
									showThumbnails={
										this.props.color.color_pictures.length >
										1
									}
									slideDuration={0}
								/>
							)}
						</Col>
						<Col sm={6} className="detail">
							<div className="set-card-title">
								<h1 style={{ fontSize: "1rem" }}>
									<span className="mute">
										CHE
										{" " + this.props.color.hauser_zusatz}
									</span>
									&nbsp;&nbsp;
									{this.props.color.znr}
									{" " + this.props.color.color}
								</h1>
								{this.props.color.getBeschrieb(
									this.state.language
								) && (
									<p>
										<b>
											{" " +
												this.props.color.getBeschrieb(
													this.state.language
												)}
										</b>
									</p>
								)}
							</div>
							<hr className="zero-line" />
							<p>
								<span className="year">
									{this.props.color.jahr}
								</span>
								&nbsp;
								{this.props.color.getName(this.state.language)}
								{this.props.color.getLangbeschrieb(
									this.state.language
								) !== "" && (
									<>
										<br />
										{this.props.color.getLangbeschrieb(
											this.state.language
										)}
									</>
								)}
								<br />
								{this.props.color.getKurzbeschrieb(
									this.state.language
								)}
							</p>
							<hr className="zero-line" />
							{this.renderSpecialInfos()}
							{this.renderPrices()}
							<AdditionalInformations
								product={this.props.color}
							/>
						</Col>
					</Row>
				</article>
			</>
		);
	}
}
