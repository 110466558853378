import Category from "./Category";

export default class CategoryCollection {
	collection: Category[];

	constructor() {
		this.collection = [];
	}

	findHauserZusatz(input: number): string {
		let found = this.collection.find((category) => (category.id = input));

		if (found !== undefined) {
			return found.hauser_zusatz;
		} else {
			return "";
		}
	}

	getIdArray(): number[] {
		return this.collection.map((c) => {
			return c.id;
		});
	}

	findTitle(categoryId: number): string {
		let found = this.collection.find(
			(category) => category.id === categoryId
		);
		if (found !== undefined) {
			return found.level_2;
		} else {
			return "Schweiz";
		}
	}

	readApi(Store: any) {
		let _this = this;
		fetch("https://api.rj-shop.ch/categories", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then(function (response) {
				if (response.ok) return response.json();
				throw new Error(_this.constructor.name);
			})
			.then(function (json) {
				_this.translateApi(json);
				Store.notify();
			})
			.catch(function (err) {
				console.error("error: " + err);
			});
	}

	translateApi(json: any): void {
		for (let i in json) {
			let category: Category = new Category();
			let level_2: string = json[i].kat_level_2;
			let exists: number | boolean = false;

			// Check if it exists
			for (let z in this.collection) {
				if (level_2 === this.collection[z].level_2) {
					exists = Number(z);
				}
			}
			if (
				level_2 !== "Vorphilatelie" &&
				level_2 !== "Sammlungen" &&
				level_2 !== "Frankaturware"
			) {
				// Consequences, if it exists
				if (exists !== false) {
					this.collection[exists].involvedIds.push(json[i].kat_id);
				} else {
					category.translateApi(json[i]);
					this.collection.push(category);
				}
			}
		}
	}
}
