import Color from "../models/Color";

export default class ColorCollection {
	collection: Color[] = [];

	constructor() {
		this.collection = [];
	}

	add(color: Color) {
		this.collection.push(color)
	}

	all(): Color[] {
		return this.collection;
	}
}
