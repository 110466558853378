import Observer from "../observerstore/Observer";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import React from "react";
import Group from "../models/Group";
import Letters from "../components/Letters";
import Colors from "../components/Colors";
import Varieties from "../components/Varieties";
import Product from "../models/Product";
import "../css/letter.css";
import * as T from "../language/GroupCardBottomDetail";
import Store from "../observerstore/Store";
import { Language } from "../types/Language";
const $ = Store.data;

interface Props {
	input: Group | Product;
}

interface State {
	detail: boolean;
	colors: boolean;
	variety: boolean;
	stamp: boolean;
	letter: boolean;
	language: Language;
}

interface ButtonInformation {
	id: keyof State;
	disabled: boolean;
	name: string;
}

export default class GroupCardBottomDetail extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			detail: false,
			colors: false,
			variety: false,
			stamp: false,
			letter: false,
			language: $.language,
		};
		this.click = this.click.bind(this);
	}

	update() {
		this.setStateNStore({
			language: $.language,
		});
	}

	getButtonInformations(): ButtonInformation[] {
	return [
		{
			id: "detail",
			disabled: true,
			name: T.details(this.state.language),
		},
		{
			id: "colors",
			disabled: !this.props.input.hasColors(),
			name: T.colors(this.state.language),
		},
		{
			id: "variety",
			disabled: !this.props.input.hasVarieties(),
			name: T.variety(this.state.language),
		},
		{
			id: "stamp",
			disabled: true,
			name: T.stamp(this.state.language),
		},
		{
			id: "letter",
			disabled: !this.props.input.hasLetters(),
			name: T.letter(this.state.language),
		},
	];
	}

	click<K extends keyof State>(event: any): void {
		let key: K = event.target.id;
		let viewBool: boolean = !this.state[key];
		let newState: any = {
			detail: false,
			colors: false,
			variety: false,
			stamp: false,
			letter: false,
		};
		newState[key] = viewBool;
		this.setState(newState);
	}

	render() {
		return (
			<React.Fragment>
				<div className="letterbox-detail">
					<code className="component_info">
						{this.constructor.name}
					</code>
					<ButtonGroup
						aria-label="Basic example"
						style={{ marginTop: "10px" }}
						className="d-flex"
					>
						{this.getButtonInformations().map((b, i: number) => (
							<Button
								key={i}
								id={b.id}
								variant="outline-primary"
								onClick={this.click}
								size="sm"
								active={this.state[b.id] !== false}
								disabled={b.disabled}
							>
								{b.name}
							</Button>
						))}
					</ButtonGroup>
					<div className="groupcard-bottom-info">
						{this.state.letter === true && (
							<Letters input={this.props.input} />
						)}
						{this.state.variety === true && (
							<Varieties input={this.props.input} />
						)}
						{this.state.colors === true && (
							<Colors input={this.props.input} />
						)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}
