import { REQUESTURL } from "../constants/urls";
import Auction from "../models/Auction";
import Store from "../observerstore/Store";

export class AuctionCollectionController {
	auctionCollection: Auction[];

	constructor() {
		this.auctionCollection = [];
	}

	get(): Auction[] {
		return this.auctionCollection;
	}

	findTitel(id: number): string {
		const auction = this.auctionCollection.find(
			auction => auction.id === id
		);
		return auction !== undefined ? auction.name : "";
	}

	readAPI(): void {
		const url = `${REQUESTURL}alle_auktionen`;
		const _this = this;

		fetch(url)
			.then(res => res.json())
			.then(data => {
				data.forEach(function(auction: any) {
					_this.auctionCollection.push(
						new Auction(
							Number(auction.auk_id),
							String(auction.auk_name)
						)
					);
				});
				this.notifyStore();
			})
			.catch(console.log);
	}

	notifyStore(): void {
		Store.notify();
	}
}

export default AuctionCollectionController;
