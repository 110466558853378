import { Language } from "../types/Language";

export function validTo(language: Language) {
	switch (language) {
		case "de":
			return 'Gültig bis:'
		case "fr":
			return 'Valable jusqu’au :'
		default:
			return 'Gültig bis:'
	}
}
