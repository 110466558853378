import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Language } from "../types/Language";
import T from "../language/Datenschutz";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";
const $ = Store.data;

interface Props {}
interface State {
	language: Language;
}

class Datenschutz extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			language: $.language,
		};
		console.log("x");
	}

	public componentDidMount(): void {
		Store.attach(this);
		this.update();
	}

	public update(): void {
		this.setStateNStore({
			language: $.language,
		});
	}

	render() {
		return (
			<>
				<Container>
					<code className="component_info">Datenschutz.js</code>
					<Row>
						<Col sm={9}>
							<div className="main">{T(this.state.language)}</div>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default Datenschutz; // Don’t forget to use export default!
