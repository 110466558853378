import ProductCollection from "../models/ProductCollection";
import AuctionCollectionController from "../controllers/AuctionCollectionController";
import CategoryCollection from "../models/CategoryCollection";
import GroupCollection from "../models/GroupCollection";
import ShoppingCart from "../models/ShoppingCart";
import Group from "../models/Group";
import Contact from "../models/Contact";
import ObserverType from "./ObserverType";
import BASISCATEGORIES from "../constants/basisCategories";
import { Language } from "../types/Language";

interface data {
	categoryCollection: CategoryCollection;
	groupCollection: GroupCollection;
	productCollection: ProductCollection;
	group: Group;
	auctionCollection: AuctionCollectionController;
	shoppingCart: ShoppingCart;
	contact: Contact;
	showSidebar: boolean;
	view: number;
	language: Language;
	url: {
		anzahl: number;
		seite: number;
		katId: number;
		sortAttribute: string;
		sortOrder: string;
		searchTerm: string;
		searchType: string;
		categories: number[];
	};
}

class Store {
	observers: ObserverType[];

	data: data;

	constructor() {
		this.observers = [];
		this.data = {
			categoryCollection: new CategoryCollection(),
			groupCollection: new GroupCollection(),
			productCollection: new ProductCollection(),
			group: new Group(),
			auctionCollection: new AuctionCollectionController(),
			shoppingCart: new ShoppingCart(),
			contact: new Contact(),
			showSidebar: true,
			view: 1,
			language: "de",
			url: {
				anzahl: 9,
				seite: 1,
				katId: 0,
				sortAttribute: "all",
				sortOrder: "ASC",
				searchTerm: "",
				searchType: "all",
				categories: BASISCATEGORIES,
			},
		};
	}

	attach(observer: ObserverType): void {
		this.observers.push(observer);
	}

	detach(observer: ObserverType): void {
		this.observers = this.observers.filter((o) => o !== observer);
	}

	print(): void {
		console.log(this.data);
	}

	notify(): void {
		this.observers.forEach((observer) => observer.update());
	}
}

export default new Store();
