import React from "react";

class Error extends React.Component {
	render() {
		return (
			<>
				<div className="text-center" style={{marginTop: "40px"}}>
				<h1>404</h1>
				<hr />
				<p>Hier wurde leider nichts gefunden.</p>
				<p>Zurück zur <a href="#/">Startseite</a> …</p>
				</div>
			</>
		);
	}
}

export default Error;
