import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import React from "react";
import * as T from "../language/navigation.json";
import logo from "../img/Logo_RJ_gold.png";
import { Language } from "../types/Language";
import Store from "../observerstore/Store";
import Observer from "../observerstore/Observer";

const $ = Store.data;
const languageArray = [
	{ name: "de", disabled: false },
	{ name: "fr", disabled: false },
	{ name: "it", disabled: true },
	{ name: "en", disabled: true },
];

interface Props {}
interface State {
	language: Language;
}

class Navigation extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			language: $.language,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	public componentDidMount(): void {
		Store.attach(this);
		this.readLocalStorage();
	}

	public update(): void {
		this.setStateNStore({
			language: $.language,
		});
	}

	private createLocalStorage(language: Language): void {
		localStorage.setItem("language", language);
	}

	private readLocalStorage(): void {
		let language: string | null = localStorage.getItem("language");
		languageArray.forEach((item) => {
			if (item.name === language) {
				$.language = language as Language;
				Store.notify();
			}
		});
	}

	private handleChange(event: any): void {
		this.createLocalStorage(event.target.id);
		this.readLocalStorage();
	}

	public render() {
		return (
			<>
				<div className="headerbox">
					<Container>
						<div className="headerlogo">
							<Image src={logo} alt="Logo R&J Stamp" fluid />
						</div>
					</Container>
				</div>
				<Navbar bg="dark" variant="dark" expand="lg">
					<Container>
						<Navbar.Brand href="/" className="d-lg-none">
							RJ Stamp
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="mr-auto">
								<Nav.Link href="#/overview/0/15/1">
									{T.schweiz[this.state.language]}
								</Nav.Link>
								<Nav.Link href="#/liechtenstein">
									{T.liechtenstein[this.state.language]}
								</Nav.Link>
								<Nav.Link href="#/deutschland">
									{T.deutschland[this.state.language]}
								</Nav.Link>
								<Nav.Link href="#/frankreich">
									{T.frankreich[this.state.language]}
								</Nav.Link>
								<Nav.Link href="#/oesterreich">
									{T.oesterreich[this.state.language]}
								</Nav.Link>
								<Nav.Link href="#/england">
									{T.england[this.state.language]}
								</Nav.Link>
								<Nav.Link href="#/usa">
									{T.usa[this.state.language]}
								</Nav.Link>
							</Nav>
							<Nav className="justify-content-end mr-right">
								<NavDropdown
									title={this.state.language.toUpperCase()}
									id="basic-nav-dropdown"
								>
									{languageArray.map((l: any, i: number) => (
										<NavDropdown.Item
											active={
												l.name === this.state.language
											}
											key={i}
											disabled={l.disabled}
											onClick={this.handleChange}
											id={l.name}
										>
											{l.name.toUpperCase()}
											{l.disabled && " (in Vorbereitung)"}
										</NavDropdown.Item>
									))}
								</NavDropdown>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
				<code className="component_info">{this.constructor.name}</code>
			</>
		);
	}
}

export default Navigation;
