import Variety from "../models/Variety";

export default class VarietyCollection {
	collection: Variety[] = [];

	constructor() {
		this.collection = [];
	}

	add(variety: Variety) {
		this.collection.push(variety)
	}

	all(): Variety[] {
		return this.collection;
	}
}
