import { Language } from "../types/Language";

export function details(language: Language) {
	switch (language) {
		case "de":
			return 'Details'
		case "fr":
			return 'Détails'
		default:
			return 'Details'
	}
}

export function colors(language: Language) {
	switch (language) {
		case "de":
			return 'Farben'
		case "fr":
			return 'Couleurs'
		default:
			return 'Farben'
	}
}

export function variety(language: Language) {
	switch (language) {
		case "de":
			return 'Abarten'
		case "fr":
			return 'Variétés'
		default:
			return 'Abarten'
	}
}

export function stamp(language: Language) {
	switch (language) {
		case "de":
			return 'Stempel'
		case "fr":
			return 'Tampon'
		default:
			return 'Stempel'
	}
}

export function letter(language: Language) {
	switch (language) {
		case "de":
			return 'Briefe'
		case "fr":
			return 'Lettres'
		default:
			return 'Briefe'
	}
}
