import React from "react";
import { Language } from "../types/Language";

export default function T(language: Language) {
	switch (language) {
		case "fr":
			return (
				<>
					<h1>Politique de confidentialité</h1>
					<p>
						La protection des données personnelles étant importante
						pour nous, nous souhaitons vous informer de la manière
						dont nous traitons vos données. traiter vos données.
						<br />
						En utilisant ce site web, vous donnez votre consentir au
						traitement de vos données personnelles dans les décrits
						ci-dessous et dans le but indiqué. L'objectif peut être
						traité.
					</p>
					<hr />
					<h2>1. Verantwortlicher</h2>
					<p>
						Verantwortlicher für die Datenverarbeitung und
						Entscheidungsträger bezüglich Zweck und Mittel der
						Verarbeitung von personenbezogenen Daten im Zusammenhang
						mit den Services ist:
					</p>
					<p>
						R&amp;J universal AG
						<br />
						Bösch 41
						<br />
						6331 Hünenberg
						<br />
						Schweiz
						<br />
						Telefon: +41 44 777 88 22
						<br />
						E-Mail: info@rj-stamp.ch
					</p>
					<hr />
					<h2>2. Grundlegendes</h2>
					<p>
						Diese Datenschutzerklärung soll die Nutzer dieser
						Website gemäss Bundesdatenschutzgesetz und
						Telemediengesetz über die Art, den Umfang und den Zweck
						der Erhebung und Verwendung personenbezogener Daten
						durch die R&amp;J universal AG informieren.
					</p>
					<p>
						Die R&amp;J universal AG nimmt Ihren Datenschutz sehr
						ernst und behandelt Ihre personenbezogenen Daten
						vertraulich und entsprechend der gesetzlichen
						Vorschriften.
						<br />
						Bedenken Sie, dass die Datenübertragung im Internet
						grundsätzlich mit Sicherheitslücken bedacht sein kann.
						Ein vollumfänglicher Schutz vor dem Zugriff durch Fremde
						ist nicht realisierbar.
						<br />
						Da durch neue Technologien und die ständige
						Weiterentwicklung dieser Webseite Änderungen an dieser
						Datenschutzerklärung vorgenommen werden können,
						empfehlen wir Ihnen sich die Datenschutzerklärung in
						regelmässigen Abständen wieder durchzulesen.
					</p>
					<p>
						Definitionen der verwendeten Begriffe (z.B.
						«personenbezogene Daten» oder «Verarbeitung») finden Sie
						in Art. 4 der DSGVO.
					</p>
					<hr />
					<h2>3. Zugriffsdaten</h2>
					<p>
						Die R&amp;J universal AG bzw. der Seitenprovider erhebt
						aufgrund des berechtigten Interesses (s. Art. 6 Abs. 1
						lit. f. DSGVO) Daten über Zugriffe auf die Website und
						speichert diese als sogenannte «Server-Logfiles» auf dem
						Server der Website ab. Nachfolgende Daten werden dadurch
						protokolliert:
					</p>
					<ul>
						<li>Besuchte Website</li>
						<li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
						<li>Menge der gesendeten Daten in Byte</li>
						<li>
							Quelle/Verweis, von welchem Sie auf die Seite
							gelangten
						</li>
						<li>Verwendeter Browser</li>
						<li>Verwendetes Betriebssystem</li>
						<li>Verwendete IP-Adresse</li>
					</ul>
					<p>
						Diese genannten Protokolldaten werden jedoch nur
						anonymisiert verarbeitet. Ein Personenbezug ist dadurch
						nicht herstellbar, so dass auch eine Löschung der Daten
						nicht zwingend notwendig ist.
					</p>
					<p>
						Die Speicherung der Daten erfolgt aus
						Sicherheitsgründen, beispielsweise um Missbrauchsfälle
						aufklären zu können. Müssen Daten aus Beweisgründen
						aufgehoben werden, sind sie solange von der Löschung
						ausgenommen bis der Vorfall endgültig geklärt ist.
					</p>
					<hr />
					<h2>4. Cookies</h2>
					<p>
						Diese Website verwendet Cookies. Dabei handelt es sich
						um kleine Textdateien, welche auf Ihrem Endgerät
						gespeichert werden (Wichtig: Diese Textdateien richten
						keinen Schaden an). Ihr Browser greift auf diese Dateien
						zu. Durch den Einsatz von Cookies erhöht sich die
						Benutzerfreundlichkeit und Sicherheit dieser Website.{" "}
					</p>
					<p>
						Die in Cookies enthaltenen Informationen verwenden wir,
						um Ihnen die Nutzung unserer Seiten zu erleichtern und
						sie auf Ihre Bedürfnisse abzustimmen. Die Verarbeitung
						personenbezogener Daten unter Verwendung technisch
						notwendiger Cookies basiert auf der Rechtsgrundlage von
						Art. 6 Abs. 1 S. 1 lit. f DSGVO. Die Rechtsgrundlage für
						die Verarbeitung Art. 6 Abs. 1 S. 1 lit. a DSGVO.
					</p>
					<p>
						Falls Sie nicht möchten, dass Cookies zur
						Reichweitenmessung auf Ihrem Endgerät gespeichert
						werden, können Sie dem Einsatz dieser Dateien hier
						widersprechen:
					</p>
					<ul>
						<li>
							Cookie-Deaktivierungsseite der
							Netzwerkwerbeinitiative:
							http://optout.networkadvertising.org/?c=1#!/
						</li>
						<li>
							Cookie-Deaktivierungsseite der europäischen Website:
							http://optout.networkadvertising.org/?c=1#!/
						</li>
						<li>
							Cookie-Deaktivierungsseite der US-amerikanischen
							Website: http://optout.aboutads.info/?c=2#!/
						</li>
					</ul>
					<p>
						Gängige Browser bieten die Einstellungsoption, Cookies
						nicht zuzulassen. Jedoch ist es nicht gewährleistet,
						dass Sie auf alle Funktionen dieser Website ohne
						Einschränkungen zugreifen können, wenn Sie entsprechende
						Einstellungen vornehmen.
					</p>
					<p>
						Cookies von Google Analytics: _ga, _gid, _gat. Diese
						Cookies werden verwendet, um das Nutzerverhalten der
						Website-Besucher zu sammeln und anschliessend
						analysieren zu können. Jedoch wird auch hier jeder
						einzelne Besucher der Website anonymisiert verarbeitet
						oder gespeichert (mittels verkürzter IP). Weitere
						Informationen zu den Funktionen finden Sie auf
						developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
					</p>
					<hr />
					<h2>5. Webtracking</h2>
					<p>
						Wir setzen auf unserer Website Webtrackingtechnologien
						ein. Dabei werden Nutzungsprofile unter einem Pseudonym
						erstellt. Diese Nutzungsprofile werden jedoch nicht dazu
						benutzt, den Besucher dieser Webseite persönlich zu
						identifizieren. Ebenso werden die Nutzungsprofile nicht
						mit personenbezogenen Daten über den Träger des
						Pseudonyms zusammengeführt. Sie können der
						Datenverarbeitung durch folgende Webtracking-Tools
						jederzeit widersprechen. Wie Sie dafür vorgehen müssen,
						lesen Sie im folgenden Abschnitt zum Webtracking von
						Google Analytics.
					</p>
					<h3>5.1. Google Analytics</h3>
					<p>
						Diese Website nutzt aufgrund unserer berechtigten
						Interessen zur Optimierung und Analyse unseres
						Online-Angebots im Sinne des Art. 6 Abs. 1 lit. f. DSGVO
						den Dienst «Google Analytics», welcher von der Google
						Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043,
						USA) angeboten wird. Der Dienst (Google Analytics)
						verwendet «Cookies» – Textdateien, welche auf Ihrem
						Endgerät gespeichert werden. Die durch die Cookies
						gesammelten Informationen werden im Regelfall an einen
						Google-Server in den USA gesandt und dort gespeichert.
					</p>
					<p>
						Google LLC hält das europäische Datenschutzrecht ein und
						ist unter dem Privacy-Shield-Abkommen zertifiziert:
						https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
					</p>
					<p>
						Auf dieser Website greift die IP-Anonymisierung. Die
						IP-Adresse der Nutzer wird innerhalb der
						Mitgliedsstaaten der EU und des Europäischen
						Wirtschaftsraums und in den anderen Vertragsstaaten des
						Abkommens gekürzt. Nur in Einzelfällen wird die
						IP-Adresse zunächst ungekürzt in die USA an einen Server
						von Google übertragen und dort gekürzt. Durch diese
						Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Die
						vom Browser übermittelte IP-Adresse des Nutzers wird
						nicht mit anderen von Google gespeicherten Daten
						kombiniert.
					</p>
					<p>
						Im Rahmen der Vereinbarung zur
						Auftragsdatenvereinbarung, welche wir als
						Websitebetreiber mit der Google Inc. geschlossen haben,
						erstellt diese mithilfe der gesammelten Informationen
						eine Auswertung der Websitenutzung und der
						Websiteaktivität und erbringt mit der Internetnutzung
						verbundene Dienstleistungen.
					</p>
					<p>
						Die von Google in unserem Auftrag erhobenen Daten werden
						verwendet, um die Nutzung unseres Online-Angebots durch
						die einzelnen Besucher auswerten zu können, z.B. um
						Reports über die Aktivität auf der Website zu erstellen,
						und somit unser Online-Angebot zu verbessern.
					</p>
					<p>
						Sie haben die Möglichkeit, die Speicherung der Cookies
						auf Ihrem Gerät zu verhindern. Dazu gibt es drei
						Möglichkeiten:
					</p>
					<ol>
						<li>
							Zum einen indem Sie in Ihrem Browser entsprechende
							Einstellungen vornehmen. Es ist jedoch nicht
							gewährleistet, dass Sie auf alle Funktionen dieser
							Website ohne Einschränkungen zugreifen können, wenn
							Ihr Browser keine Cookies zulässt.
						</li>
						<li>
							Eine weitere Möglichkeit ist es, mit einem
							Browser-Plugin zu verhindern, dass die durch Cookies
							gesammelten Informationen (inklusive Ihrer
							IP-Adresse) an die Google Inc. gesendet und von der
							Google Inc. genutzt werden. Folgender Link führt Sie
							zu dem entsprechenden Plugin:
							https://tools.google.com/dlpage/gaoptout?hl=de
						</li>
						<li>
							Alternativ verhindern Sie mit einem Klick auf diesen
							Link (WICHTIG: Opt-Out-Link einfügen), dass Google
							Analytics innerhalb dieser Website Daten über Sie
							erfasst. Mit dem Klick auf obigen Link laden Sie ein
							«Opt-Out-Cookie» herunter. Ihr Browser muss die
							Speicherung von Cookies also hierzu grundsätzlich
							erlauben. Löschen Sie Ihre Cookies regelmässig, ist
							ein erneuter Klick auf den Link bei jedem Besuch
							dieser Website erforderlich.
						</li>
					</ol>
					<p>
						Weitere Informationen zur Datennutzung durch die Google
						Inc. finden Sie hier:
					</p>
					<ul>
						<li>
							Daten, die von Google-Partnern erhoben werden:
							https://policies.google.com/privacy/partners?hl=de
						</li>
						<li>
							Einstellungen über Werbung, die Ihnen angezeigt
							wird: https://adssettings.google.de/authenticated
						</li>
						<li>
							Verwendung von Cookies in Anzeigen:
							https://policies.google.com/technologies/ads?hl=de
						</li>
					</ul>
					<hr />
					<h2>6. Umgang mit Ihren persönlichen Daten</h2>
					<h3>6.1 Generell</h3>
					<p>
						Der Websitebetreiber erhebt, nutzt und gibt Ihre
						personenbezogenen Daten nur dann weiter, wenn dies im
						gesetzlichen Rahmen erlaubt ist oder Sie in die
						Datenerhebung einwilligen (z.B. beim Kontakt-Formular).
						Als personenbezogene Daten gelten sämtliche
						Informationen, welche dazu dienen, Ihre Person zu
						bestimmen und welche zu Ihnen zurückverfolgt werden
						können – also beispielsweise Ihr Name, Ihre
						E-Mail-Adresse und Telefonnummer.
					</p>
					<p>
						Diese Website können Sie auch besuchen, ohne Angaben zu
						Ihrer Person zu machen. Zur Verbesserung unseres
						Online-Angebotes speichern wir jedoch (ohne
						Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu
						diesen Zugriffsdaten gehören z.B. die von Ihnen
						angeforderte Datei oder der Name Ihres
						Internet-Providers. Durch die Anonymisierung der Daten
						sind Rückschlüsse auf Ihre Person nicht möglich.
					</p>
					<p>
						Wenn Sie uns online persönliche Daten (z.B. per E-Mail
						oder anhand von Web-Formularen) übermitteln, werden
						diese entsprechend der gesetzlichen Vorschriften erfasst
						und verarbeitet. Wir geben Ihre Daten nur an Dritte
						weiter, wenn wir dazu gesetzlich berechtigt oder
						verpflichtet sind (z.B. zu Vertrags- oder
						Strafverfolgungszwecken) oder wenn dies Ihr
						ausdrücklicher Wunsch ist.
					</p>
					<p>
						Falls Sie nicht möchten, dass wir Ihre Daten für
						Werbezwecke und Auswertungen nutzen, können Sie uns dies
						mitteilen bzw. Ihre Einwilligung jederzeit widerrufen.
						Kontaktieren Sie uns in diesem Fall (Kontaktdaten siehe
						oben). Unter dem nachfolgenden Punkt «Ihre Rechte»
						erfahren Sie mehr dazu. Der Widerruf berührt die
						Rechtmässigkeit der bisher erfolgten Verarbeitung nicht.
						Wenn Sie Ihre Einwilligung widerrufen, stellen wir die
						entsprechende Datenverarbeitung ein.
					</p>
					<h3>6.2 Bestelldaten</h3>
					<p>
						Sämtliche Daten, welche im Rahmen einer
						Bestellabwicklung von den Kunden eingegeben werden,
						werden gespeichert. Dazu gehören:
					</p>
					<ul>
						<li>Name, Vorname</li>
						<li>Adresse</li>
						<li>Telefonnummer</li>
						<li>E-Mail-Adresse</li>
						<li>Zahldaten</li>
					</ul>
					<p>
						Jene Daten, welche zur Auslieferung oder
						Auftragsabwicklung zwingend notwendig sind, werden an
						dritte Dienstleister weitergegeben. Sowie die
						Aufbewahrung Ihrer Daten nicht mehr erforderlich oder
						gesetzlich geboten ist, werden diese gelöscht.
					</p>
					<h3>6.3 Kontaktdaten</h3>
					<p>
						Nehmen Sie mit der R&amp;J universal AG durch die
						angebotenen Kontaktmöglichkeiten Verbindung auf, werden
						Ihre Angaben gespeichert, damit auf diese zur
						Bearbeitung und Beantwortung Ihrer Anfrage
						zurückgegriffen werden kann. Ohne Ihre Einwilligung
						werden diese Daten nicht an Dritte weitergegeben.
					</p>
					<h3>6.4 Abonnemente</h3>
					<p>
						Sie haben die Möglichkeit, sowohl die gesamte Website
						als auch Nachfolgekommentare auf Ihren Beitrag zu
						abonnieren. Sie erhalten eine E-Mail zur Bestätigung
						Ihrer E-Mail-Adresse. Neben dieser werden keine weiteren
						Daten erhoben. Die gespeicherten Daten werden nicht an
						Dritte weitergereicht. Sie können ein Abonnement auch
						jederzeit abbestellen.
					</p>
					<h3>6.5 Reklamationen</h3>
					<p>
						Angaben, die Sie uns im Rahmen von Reklamationen
						übermitteln, verwenden wir ausschliesslich zur
						Bearbeitung Ihres Anliegens. Die Datenverarbeitung
						erfolgt auf der Grundlage von Art. 6 Abs. 1 S. 1 lit. f
						DSGVO und in dem Interesse, Ihnen einen kompetenten und
						zweckmässigen Kundenservice zu bieten.
						<br />
						Sie haben das Recht, der Datenverarbeitung zu
						widersprechen. Unter dem nachfolgenden Punkt «Ihre
						Rechte» erfahren Sie mehr dazu. Sobald Ihre Daten für
						die vorhin genannten Zwecke nicht mehr erforderlich
						sind, werden sie gelöscht. Soweit Daten aus gesetzlichen
						Gründen aufbewahrt werden müssen, werden diese gesperrt.
						Die Daten stehen einer weiteren Verwendung dann nicht
						mehr zur Verfügung.
					</p>
					<hr />
					<h2>
						7. Weiterleitung auf andere Webseiten und soziale
						Netzwerke
					</h2>
					<p>
						Auf unserer Website führen gewisse Links auch zu
						Angeboten anderer Webanbieter oder auf soziale
						Netzwerke. Solche Links erkennen Sie am jeweiligen
						Texthinweis, oder daran, dass es sich um das Logo einer
						anderen Firma als das der R&amp;J universal AG handelt.
						Da es sich bei diesen Links nicht um Webangebote der
						R&amp;J universal AG handelt, können wir für die
						Einhaltung der Datenschutzbestimmungen der dortigen
						Anbieter keine Gewähr bieten. Bei Fragen zum Datenschutz
						kontaktieren Sie bitte die dortigen Anbieter.
					</p>
					<p>
						Auch auf sozialen Netzwerken können Webtracking-Methoden
						zum Einsatz kommen. Darum können wir nicht
						ausschliessen, dass soziale Netzwerke Ihre Profildaten
						nutzen, etwa um Ihre Gewohnheiten, persönlichen
						Beziehungen, Vorlieben usw. auszuwerten. Wir haben
						keinerlei Einfluss auf die Verarbeitung Ihrer Daten
						durch die sozialen Netzwerke. Bitte beachten Sie darum
						auf den Seiten sozialer Netzwerke auch unsere dortige
						Datenschutzerklärung sowie die Erklärungen der Anbieter.
					</p>
					<hr />
					<h2>8. Datensicherheit</h2>
					<p>
						Wir treffen technische und organisatorische
						Sicherheitsmassnahmen um Ihre Daten vor unerwünschten
						Zugriffen möglichst umfassend zu schützen. Neben der
						Verpflichtung unserer Mitarbeiter auf die
						Vertraulichkeit, der sorgfältigen Auswahl und Kontrolle
						von Dienstleistern und der Absicherung unserer
						Betriebsumgebung setzen wir zum Beispiel in einigen
						Bereichen ein Verschlüsselungsverfahren ein. Die von
						Ihnen angegebenen Informationen werden dann in
						verschlüsselter Form mittels eines SSL-Protokolls
						(Secure Socket Layer) übertragen und authentisch
						geprüft, um einem Missbrauch der Daten durch Dritte
						vorzubeugen. Sie erkennen dies daran, dass in der
						Statusleiste Ihres Browsers das Schloss-Symbol
						geschlossen ist und die Adresszeile mit https. beginnt.
					</p>
					<hr />
					<h2>9. Rechte des Nutzers</h2>
					<p>
						Sie haben als Nutzer das Recht, auf Antrag eine
						kostenlose Auskunft darüber zu erhalten, welche
						personenbezogenen Daten über Sie gespeichert wurden. Sie
						haben ausserdem das Recht auf Berichtigung falscher
						Daten und auf die Verarbeitungseinschränkung oder
						Löschung Ihrer personenbezogenen Daten. Falls
						zutreffend, können Sie auch Ihr Recht auf
						Datenportabilität geltend machen. Sollten Sie annehmen,
						dass Ihre Daten unrechtmässig verarbeitet wurden, können
						Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde
						einreichen.
					</p>
					<h3>9.1 Löschung von Daten</h3>
					<p>
						Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht
						zur Aufbewahrung von Daten (z. B.
						Vorratsdatenspeicherung) kollidiert, haben Sie ein
						Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte
						Daten werden, sollten sie für ihre Zweckbestimmung nicht
						mehr vonnöten sein und es keine gesetzlichen
						Aufbewahrungsfristen geben, gelöscht. Falls eine
						Löschung nicht durchgeführt werden kann, da die Daten
						für zulässige gesetzliche Zwecke erforderlich sind,
						erfolgt eine Einschränkung der Datenverarbeitung. In
						diesem Fall werden die Daten gesperrt und nicht für
						andere Zwecke verarbeitet.
					</p>
					<h3>9.2 Widerspruchsrecht</h3>
					<p>
						Nutzer dieser Webseite können von ihrem
						Widerspruchsrecht Gebrauch machen und der Verarbeitung
						ihrer personenbezogenen Daten zu jeder Zeit
						widersprechen.
					</p>
					<p>
						Wenn Sie eine Berichtigung, Sperrung, Löschung oder
						Auskunft über die zu Ihrer Person gespeicherten
						personenbezogenen Daten wünschen oder Fragen bzgl. der
						Erhebung, Verarbeitung oder Verwendung Ihrer
						personenbezogenen Daten haben oder erteilte
						Einwilligungen widerrufen möchten, wenden Sie sich bitte
						an folgende E-Mail-Adresse: [E-Mail-Adresse einfügen]
					</p>
				</>
			);
		default:
			return (
				<>
					<h1>Datenschutzerklärung</h1>
					<p>
						Da uns der Schutz persönlicher Daten wichtig ist,
						möchten wir Sie nachfolgend darüber informieren, wie wir
						mit Ihren Daten umgehen.
						<br />
						Durch die Nutzung dieser Website geben Sie Ihr
						Einverständnis, dass Ihre persönlichen Daten in der
						nachfolgend beschriebenen Weise und zu dem genannten
						Zweck behandelt werden dürfen.
					</p>

					<hr />

					<h2>1. Verantwortlicher</h2>
					<p>
						Verantwortlicher für die Datenverarbeitung und
						Entscheidungsträger bezüglich Zweck und Mittel der
						Verarbeitung von personenbezogenen Daten im Zusammenhang
						mit den Services ist:
					</p>
					<p>
						R&amp;J universal AG
						<br />
						Bösch 41
						<br />
						6331 Hünenberg
						<br />
						Schweiz
						<br />
						Telefon: +41 44 777 88 22
						<br />
						E-Mail: info@rj-stamp.ch
					</p>

					<hr />

					<h2>2. Grundlegendes</h2>
					<p>
						Diese Datenschutzerklärung soll die Nutzer dieser
						Website gemäss Bundesdatenschutzgesetz und
						Telemediengesetz über die Art, den Umfang und den Zweck
						der Erhebung und Verwendung personenbezogener Daten
						durch die R&amp;J universal AG informieren.
					</p>
					<p>
						Die R&amp;J universal AG nimmt Ihren Datenschutz sehr
						ernst und behandelt Ihre personenbezogenen Daten
						vertraulich und entsprechend der gesetzlichen
						Vorschriften.
						<br />
						Bedenken Sie, dass die Datenübertragung im Internet
						grundsätzlich mit Sicherheitslücken bedacht sein kann.
						Ein vollumfänglicher Schutz vor dem Zugriff durch Fremde
						ist nicht realisierbar.
						<br />
						Da durch neue Technologien und die ständige
						Weiterentwicklung dieser Webseite Änderungen an dieser
						Datenschutzerklärung vorgenommen werden können,
						empfehlen wir Ihnen sich die Datenschutzerklärung in
						regelmässigen Abständen wieder durchzulesen.
					</p>
					<p>
						Definitionen der verwendeten Begriffe (z.B.
						«personenbezogene Daten» oder «Verarbeitung») finden Sie
						in Art. 4 der DSGVO.
					</p>

					<hr />

					<h2>3. Zugriffsdaten</h2>
					<p>
						Die R&amp;J universal AG bzw. der Seitenprovider erhebt
						aufgrund des berechtigten Interesses (s. Art. 6 Abs. 1
						lit. f. DSGVO) Daten über Zugriffe auf die Website und
						speichert diese als sogenannte «Server-Logfiles» auf dem
						Server der Website ab. Nachfolgende Daten werden dadurch
						protokolliert:
					</p>
					<ul>
						<li>Besuchte Website</li>
						<li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
						<li>Menge der gesendeten Daten in Byte</li>
						<li>
							Quelle/Verweis, von welchem Sie auf die Seite
							gelangten
						</li>
						<li>Verwendeter Browser</li>
						<li>Verwendetes Betriebssystem</li>
						<li>Verwendete IP-Adresse</li>
					</ul>
					<p>
						Diese genannten Protokolldaten werden jedoch nur
						anonymisiert verarbeitet. Ein Personenbezug ist dadurch
						nicht herstellbar, so dass auch eine Löschung der Daten
						nicht zwingend notwendig ist.
					</p>
					<p>
						Die Speicherung der Daten erfolgt aus
						Sicherheitsgründen, beispielsweise um Missbrauchsfälle
						aufklären zu können. Müssen Daten aus Beweisgründen
						aufgehoben werden, sind sie solange von der Löschung
						ausgenommen bis der Vorfall endgültig geklärt ist.
					</p>

					<hr />

					<h2>4. Cookies</h2>
					<p>
						Diese Website verwendet Cookies. Dabei handelt es sich
						um kleine Textdateien, welche auf Ihrem Endgerät
						gespeichert werden (Wichtig: Diese Textdateien richten
						keinen Schaden an). Ihr Browser greift auf diese Dateien
						zu. Durch den Einsatz von Cookies erhöht sich die
						Benutzerfreundlichkeit und Sicherheit dieser Website.{" "}
					</p>
					<p>
						Die in Cookies enthaltenen Informationen verwenden wir,
						um Ihnen die Nutzung unserer Seiten zu erleichtern und
						sie auf Ihre Bedürfnisse abzustimmen. Die Verarbeitung
						personenbezogener Daten unter Verwendung technisch
						notwendiger Cookies basiert auf der Rechtsgrundlage von
						Art. 6 Abs. 1 S. 1 lit. f DSGVO. Die Rechtsgrundlage für
						die Verarbeitung Art. 6 Abs. 1 S. 1 lit. a DSGVO.
					</p>
					<p>
						Falls Sie nicht möchten, dass Cookies zur
						Reichweitenmessung auf Ihrem Endgerät gespeichert
						werden, können Sie dem Einsatz dieser Dateien hier
						widersprechen:
					</p>
					<ul>
						<li>
							Cookie-Deaktivierungsseite der
							Netzwerkwerbeinitiative:
							http://optout.networkadvertising.org/?c=1#!/
						</li>
						<li>
							Cookie-Deaktivierungsseite der europäischen Website:
							http://optout.networkadvertising.org/?c=1#!/
						</li>
						<li>
							Cookie-Deaktivierungsseite der US-amerikanischen
							Website: http://optout.aboutads.info/?c=2#!/
						</li>
					</ul>
					<p>
						Gängige Browser bieten die Einstellungsoption, Cookies
						nicht zuzulassen. Jedoch ist es nicht gewährleistet,
						dass Sie auf alle Funktionen dieser Website ohne
						Einschränkungen zugreifen können, wenn Sie entsprechende
						Einstellungen vornehmen.
					</p>
					<p>
						Cookies von Google Analytics: _ga, _gid, _gat. Diese
						Cookies werden verwendet, um das Nutzerverhalten der
						Website-Besucher zu sammeln und anschliessend
						analysieren zu können. Jedoch wird auch hier jeder
						einzelne Besucher der Website anonymisiert verarbeitet
						oder gespeichert (mittels verkürzter IP). Weitere
						Informationen zu den Funktionen finden Sie auf
						developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
					</p>

					<hr />

					<h2>5. Webtracking</h2>
					<p>
						Wir setzen auf unserer Website Webtrackingtechnologien
						ein. Dabei werden Nutzungsprofile unter einem Pseudonym
						erstellt. Diese Nutzungsprofile werden jedoch nicht dazu
						benutzt, den Besucher dieser Webseite persönlich zu
						identifizieren. Ebenso werden die Nutzungsprofile nicht
						mit personenbezogenen Daten über den Träger des
						Pseudonyms zusammengeführt. Sie können der
						Datenverarbeitung durch folgende Webtracking-Tools
						jederzeit widersprechen. Wie Sie dafür vorgehen müssen,
						lesen Sie im folgenden Abschnitt zum Webtracking von
						Google Analytics.
					</p>

					<h3>5.1. Google Analytics</h3>
					<p>
						Diese Website nutzt aufgrund unserer berechtigten
						Interessen zur Optimierung und Analyse unseres
						Online-Angebots im Sinne des Art. 6 Abs. 1 lit. f. DSGVO
						den Dienst «Google Analytics», welcher von der Google
						Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043,
						USA) angeboten wird. Der Dienst (Google Analytics)
						verwendet «Cookies» – Textdateien, welche auf Ihrem
						Endgerät gespeichert werden. Die durch die Cookies
						gesammelten Informationen werden im Regelfall an einen
						Google-Server in den USA gesandt und dort gespeichert.
					</p>
					<p>
						Google LLC hält das europäische Datenschutzrecht ein und
						ist unter dem Privacy-Shield-Abkommen zertifiziert:
						https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
					</p>
					<p>
						Auf dieser Website greift die IP-Anonymisierung. Die
						IP-Adresse der Nutzer wird innerhalb der
						Mitgliedsstaaten der EU und des Europäischen
						Wirtschaftsraums und in den anderen Vertragsstaaten des
						Abkommens gekürzt. Nur in Einzelfällen wird die
						IP-Adresse zunächst ungekürzt in die USA an einen Server
						von Google übertragen und dort gekürzt. Durch diese
						Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Die
						vom Browser übermittelte IP-Adresse des Nutzers wird
						nicht mit anderen von Google gespeicherten Daten
						kombiniert.
					</p>
					<p>
						Im Rahmen der Vereinbarung zur
						Auftragsdatenvereinbarung, welche wir als
						Websitebetreiber mit der Google Inc. geschlossen haben,
						erstellt diese mithilfe der gesammelten Informationen
						eine Auswertung der Websitenutzung und der
						Websiteaktivität und erbringt mit der Internetnutzung
						verbundene Dienstleistungen.
					</p>
					<p>
						Die von Google in unserem Auftrag erhobenen Daten werden
						verwendet, um die Nutzung unseres Online-Angebots durch
						die einzelnen Besucher auswerten zu können, z.B. um
						Reports über die Aktivität auf der Website zu erstellen,
						und somit unser Online-Angebot zu verbessern.
					</p>
					<p>
						Sie haben die Möglichkeit, die Speicherung der Cookies
						auf Ihrem Gerät zu verhindern. Dazu gibt es drei
						Möglichkeiten:
					</p>
					<ol>
						<li>
							Zum einen indem Sie in Ihrem Browser entsprechende
							Einstellungen vornehmen. Es ist jedoch nicht
							gewährleistet, dass Sie auf alle Funktionen dieser
							Website ohne Einschränkungen zugreifen können, wenn
							Ihr Browser keine Cookies zulässt.
						</li>
						<li>
							Eine weitere Möglichkeit ist es, mit einem
							Browser-Plugin zu verhindern, dass die durch Cookies
							gesammelten Informationen (inklusive Ihrer
							IP-Adresse) an die Google Inc. gesendet und von der
							Google Inc. genutzt werden. Folgender Link führt Sie
							zu dem entsprechenden Plugin:
							https://tools.google.com/dlpage/gaoptout?hl=de
						</li>
						<li>
							Alternativ verhindern Sie mit einem Klick auf diesen
							Link (WICHTIG: Opt-Out-Link einfügen), dass Google
							Analytics innerhalb dieser Website Daten über Sie
							erfasst. Mit dem Klick auf obigen Link laden Sie ein
							«Opt-Out-Cookie» herunter. Ihr Browser muss die
							Speicherung von Cookies also hierzu grundsätzlich
							erlauben. Löschen Sie Ihre Cookies regelmässig, ist
							ein erneuter Klick auf den Link bei jedem Besuch
							dieser Website erforderlich.
						</li>
					</ol>
					<p>
						Weitere Informationen zur Datennutzung durch die Google
						Inc. finden Sie hier:
					</p>
					<ul>
						<li>
							Daten, die von Google-Partnern erhoben werden:
							https://policies.google.com/privacy/partners?hl=de
						</li>
						<li>
							Einstellungen über Werbung, die Ihnen angezeigt
							wird: https://adssettings.google.de/authenticated
						</li>
						<li>
							Verwendung von Cookies in Anzeigen:
							https://policies.google.com/technologies/ads?hl=de
						</li>
					</ul>

					<hr />

					<h2>6. Umgang mit Ihren persönlichen Daten</h2>

					<h3>6.1 Generell</h3>

					<p>
						Der Websitebetreiber erhebt, nutzt und gibt Ihre
						personenbezogenen Daten nur dann weiter, wenn dies im
						gesetzlichen Rahmen erlaubt ist oder Sie in die
						Datenerhebung einwilligen (z.B. beim Kontakt-Formular).
						Als personenbezogene Daten gelten sämtliche
						Informationen, welche dazu dienen, Ihre Person zu
						bestimmen und welche zu Ihnen zurückverfolgt werden
						können – also beispielsweise Ihr Name, Ihre
						E-Mail-Adresse und Telefonnummer.
					</p>

					<p>
						Diese Website können Sie auch besuchen, ohne Angaben zu
						Ihrer Person zu machen. Zur Verbesserung unseres
						Online-Angebotes speichern wir jedoch (ohne
						Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu
						diesen Zugriffsdaten gehören z.B. die von Ihnen
						angeforderte Datei oder der Name Ihres
						Internet-Providers. Durch die Anonymisierung der Daten
						sind Rückschlüsse auf Ihre Person nicht möglich.
					</p>

					<p>
						Wenn Sie uns online persönliche Daten (z.B. per E-Mail
						oder anhand von Web-Formularen) übermitteln, werden
						diese entsprechend der gesetzlichen Vorschriften erfasst
						und verarbeitet. Wir geben Ihre Daten nur an Dritte
						weiter, wenn wir dazu gesetzlich berechtigt oder
						verpflichtet sind (z.B. zu Vertrags- oder
						Strafverfolgungszwecken) oder wenn dies Ihr
						ausdrücklicher Wunsch ist.
					</p>

					<p>
						Falls Sie nicht möchten, dass wir Ihre Daten für
						Werbezwecke und Auswertungen nutzen, können Sie uns dies
						mitteilen bzw. Ihre Einwilligung jederzeit widerrufen.
						Kontaktieren Sie uns in diesem Fall (Kontaktdaten siehe
						oben). Unter dem nachfolgenden Punkt «Ihre Rechte»
						erfahren Sie mehr dazu. Der Widerruf berührt die
						Rechtmässigkeit der bisher erfolgten Verarbeitung nicht.
						Wenn Sie Ihre Einwilligung widerrufen, stellen wir die
						entsprechende Datenverarbeitung ein.
					</p>

					<h3>6.2 Bestelldaten</h3>

					<p>
						Sämtliche Daten, welche im Rahmen einer
						Bestellabwicklung von den Kunden eingegeben werden,
						werden gespeichert. Dazu gehören:
					</p>

					<ul>
						<li>Name, Vorname</li>
						<li>Adresse</li>
						<li>Telefonnummer</li>
						<li>E-Mail-Adresse</li>
						<li>Zahldaten</li>
					</ul>

					<p>
						Jene Daten, welche zur Auslieferung oder
						Auftragsabwicklung zwingend notwendig sind, werden an
						dritte Dienstleister weitergegeben. Sowie die
						Aufbewahrung Ihrer Daten nicht mehr erforderlich oder
						gesetzlich geboten ist, werden diese gelöscht.
					</p>

					<h3>6.3 Kontaktdaten</h3>

					<p>
						Nehmen Sie mit der R&amp;J universal AG durch die
						angebotenen Kontaktmöglichkeiten Verbindung auf, werden
						Ihre Angaben gespeichert, damit auf diese zur
						Bearbeitung und Beantwortung Ihrer Anfrage
						zurückgegriffen werden kann. Ohne Ihre Einwilligung
						werden diese Daten nicht an Dritte weitergegeben.
					</p>

					<h3>6.4 Abonnemente</h3>

					<p>
						Sie haben die Möglichkeit, sowohl die gesamte Website
						als auch Nachfolgekommentare auf Ihren Beitrag zu
						abonnieren. Sie erhalten eine E-Mail zur Bestätigung
						Ihrer E-Mail-Adresse. Neben dieser werden keine weiteren
						Daten erhoben. Die gespeicherten Daten werden nicht an
						Dritte weitergereicht. Sie können ein Abonnement auch
						jederzeit abbestellen.
					</p>

					<h3>6.5 Reklamationen</h3>

					<p>
						Angaben, die Sie uns im Rahmen von Reklamationen
						übermitteln, verwenden wir ausschliesslich zur
						Bearbeitung Ihres Anliegens. Die Datenverarbeitung
						erfolgt auf der Grundlage von Art. 6 Abs. 1 S. 1 lit. f
						DSGVO und in dem Interesse, Ihnen einen kompetenten und
						zweckmässigen Kundenservice zu bieten.
						<br />
						Sie haben das Recht, der Datenverarbeitung zu
						widersprechen. Unter dem nachfolgenden Punkt «Ihre
						Rechte» erfahren Sie mehr dazu. Sobald Ihre Daten für
						die vorhin genannten Zwecke nicht mehr erforderlich
						sind, werden sie gelöscht. Soweit Daten aus gesetzlichen
						Gründen aufbewahrt werden müssen, werden diese gesperrt.
						Die Daten stehen einer weiteren Verwendung dann nicht
						mehr zur Verfügung.
					</p>

					<hr />

					<h2>
						7. Weiterleitung auf andere Webseiten und soziale
						Netzwerke
					</h2>

					<p>
						Auf unserer Website führen gewisse Links auch zu
						Angeboten anderer Webanbieter oder auf soziale
						Netzwerke. Solche Links erkennen Sie am jeweiligen
						Texthinweis, oder daran, dass es sich um das Logo einer
						anderen Firma als das der R&amp;J universal AG handelt.
						Da es sich bei diesen Links nicht um Webangebote der
						R&amp;J universal AG handelt, können wir für die
						Einhaltung der Datenschutzbestimmungen der dortigen
						Anbieter keine Gewähr bieten. Bei Fragen zum Datenschutz
						kontaktieren Sie bitte die dortigen Anbieter.
					</p>

					<p>
						Auch auf sozialen Netzwerken können Webtracking-Methoden
						zum Einsatz kommen. Darum können wir nicht
						ausschliessen, dass soziale Netzwerke Ihre Profildaten
						nutzen, etwa um Ihre Gewohnheiten, persönlichen
						Beziehungen, Vorlieben usw. auszuwerten. Wir haben
						keinerlei Einfluss auf die Verarbeitung Ihrer Daten
						durch die sozialen Netzwerke. Bitte beachten Sie darum
						auf den Seiten sozialer Netzwerke auch unsere dortige
						Datenschutzerklärung sowie die Erklärungen der Anbieter.
					</p>

					<hr />

					<h2>8. Datensicherheit</h2>

					<p>
						Wir treffen technische und organisatorische
						Sicherheitsmassnahmen um Ihre Daten vor unerwünschten
						Zugriffen möglichst umfassend zu schützen. Neben der
						Verpflichtung unserer Mitarbeiter auf die
						Vertraulichkeit, der sorgfältigen Auswahl und Kontrolle
						von Dienstleistern und der Absicherung unserer
						Betriebsumgebung setzen wir zum Beispiel in einigen
						Bereichen ein Verschlüsselungsverfahren ein. Die von
						Ihnen angegebenen Informationen werden dann in
						verschlüsselter Form mittels eines SSL-Protokolls
						(Secure Socket Layer) übertragen und authentisch
						geprüft, um einem Missbrauch der Daten durch Dritte
						vorzubeugen. Sie erkennen dies daran, dass in der
						Statusleiste Ihres Browsers das Schloss-Symbol
						geschlossen ist und die Adresszeile mit https. beginnt.
					</p>

					<hr />

					<h2>9. Rechte des Nutzers</h2>

					<p>
						Sie haben als Nutzer das Recht, auf Antrag eine
						kostenlose Auskunft darüber zu erhalten, welche
						personenbezogenen Daten über Sie gespeichert wurden. Sie
						haben ausserdem das Recht auf Berichtigung falscher
						Daten und auf die Verarbeitungseinschränkung oder
						Löschung Ihrer personenbezogenen Daten. Falls
						zutreffend, können Sie auch Ihr Recht auf
						Datenportabilität geltend machen. Sollten Sie annehmen,
						dass Ihre Daten unrechtmässig verarbeitet wurden, können
						Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde
						einreichen.
					</p>

					<h3>9.1 Löschung von Daten</h3>

					<p>
						Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht
						zur Aufbewahrung von Daten (z. B.
						Vorratsdatenspeicherung) kollidiert, haben Sie ein
						Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte
						Daten werden, sollten sie für ihre Zweckbestimmung nicht
						mehr vonnöten sein und es keine gesetzlichen
						Aufbewahrungsfristen geben, gelöscht. Falls eine
						Löschung nicht durchgeführt werden kann, da die Daten
						für zulässige gesetzliche Zwecke erforderlich sind,
						erfolgt eine Einschränkung der Datenverarbeitung. In
						diesem Fall werden die Daten gesperrt und nicht für
						andere Zwecke verarbeitet.
					</p>

					<h3>9.2 Widerspruchsrecht</h3>

					<p>
						Nutzer dieser Webseite können von ihrem
						Widerspruchsrecht Gebrauch machen und der Verarbeitung
						ihrer personenbezogenen Daten zu jeder Zeit
						widersprechen.
					</p>

					<p>
						Wenn Sie eine Berichtigung, Sperrung, Löschung oder
						Auskunft über die zu Ihrer Person gespeicherten
						personenbezogenen Daten wünschen oder Fragen bzgl. der
						Erhebung, Verarbeitung oder Verwendung Ihrer
						personenbezogenen Daten haben oder erteilte
						Einwilligungen widerrufen möchten, wenden Sie sich bitte
						an folgende E-Mail-Adresse: [E-Mail-Adresse einfügen]
					</p>
				</>
			);
	}
}
