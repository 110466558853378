import Observer from "../observerstore/Observer";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import React from "react";
import * as T from "../language/SortButtons";
import { Language } from "../types/Language";
import Store from "../observerstore/Store";
const $ = Store.data;

interface Props {}
interface State {
	sortAttribute: string;
	language: Language;
}

export default class SortButtons extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			sortAttribute: "Nummer",
			language: $.language,
		};
		this.click = this.click.bind(this);
	}

	update() {
		this.setStateNStore({
			sortAttribute: $.url.sortAttribute,
			language: $.language,
		});
	}

	click(e: any) {
		switch (e) {
			case "Nummer":
				$.url.sortAttribute = "Nummer";
				$.groupCollection.readApi(
					Store,
					$.url.categories,
					$.url.anzahl,
					$.url.seite,
					"SDK",
					$.url.sortOrder,
					$.url.searchTerm,
					$.url.searchType
				);
				break;
			case "Jahr":
				$.url.sortAttribute = "Jahr";
				$.groupCollection.readApi(
					Store,
					$.url.categories,
					$.url.anzahl,
					$.url.seite,
					"Jahr",
					$.url.sortOrder,
					$.url.searchTerm,
					$.url.searchType
				);
				break;
		}
	}

	render() {
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>

				<ToggleButtonGroup
					type="radio"
					name="options"
					defaultValue={this.state.sortAttribute}
					onChange={this.click}
				>
					<ToggleButton
						value={"Jahr"}
						size="sm"
						variant="outline-primary"
					>
						{T.jahr(this.state.language)}
					</ToggleButton>
					<ToggleButton
						value={"Nummer"}
						size="sm"
						variant="outline-primary"
					>
						{T.nr(this.state.language)}
					</ToggleButton>
				</ToggleButtonGroup>
			</React.Fragment>
		);
	}
}
