import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class AGB extends Component {
	render() {
		return (
			<>
				<Container>
					<code className="component_info">AGB.js</code>
					<Row>
						<Col sm={9}>
							<div className="main">
								<h1>AGB</h1>
								<hr />

								<p>
									<i>Stand: Juni 2019</i>
								</p>
								<p>
									Bitte lesen Sie diese allgemeinen
									Geschäftsbedingungen aufmerksam durch, bevor
									Sie den Onlineshop unter www.rj-stamp.ch
									benutzen. Durch dessen Nutzung geben Sie Ihr
									Einverständnis, an diese Bedingungen
									gebunden zu sein.
								</p>
								<hr />
								<h2>Geltungsbereich</h2>
								<p>
									Betreiberin des Online Shops unter
									www.rj-stamp.ch ist:{" "}
								</p>
								<p>
									R&amp;J universal AG
									<br />
									Bösch 41
									<br />
									6331 Hünenberg
									<br />
									Schweiz
								</p>
								<p>
									Die vorliegenden Allgemeinen
									Geschäftsbedingungen (nachfolgend «AGB»)
									gelten für alle Verträge, die zwischen Ihnen
									als Kunden und uns als Betreiber des
									Online-Shops www.rj-stamp.ch im Zusammenhang
									mit dem Kauf von Waren über den Online-Shop
									abgeschlossen werden. Alle mündlichen und
									telefonischen von den ABG abweichenden
									Abmachungen müssen, um bindend zu sein,
									schriftlich bestätigt werden. Das
									Erfordernis der Schriftlichkeit ist auch
									dann erfüllt, wenn eine Erklärung in einer
									E-Mail enthalten ist.
								</p>
								<hr />
								<h2>Vertragsabschluss</h2>
								<p>
									Die Produkte und Preise im Onlineshop gelten
									als Angebot. Dieses Angebot steht jedoch
									immer unter der den Vertrag auflösenden
									Bedingung einer Lieferunmöglichkeit oder
									einer fehlerhaften Preisangabe.
									<br />
									Bestellungen der Kunden gelten als Offerte
									gegenüber der R&amp;J universal AG. Der
									Vertrag kommt erst mit der Lieferung der
									Produkte verbindlich zustande.
								</p>
								<hr />
								<h2>Registrierung</h2>
								<p>
									Bei der für die Registrierung erforderlichen
									Eingabe Ihrer persönlichen Daten sind Sie
									dafür verantwortlich, wahrheitsgemässe und
									vollständige Angaben zu machen.
								</p>
								<p>
									Personen unter 18 Jahren bzw. nicht voll
									handlungsfähige Personen bestätigen mit
									ihrer Bestellung, dass sie die Zustimmung
									ihres gesetzlichen Vertreters für den
									Abschluss des entsprechenden Geschäfts
									haben.
								</p>
								<hr />
								<h2>Preise &amp; Versandkosten</h2>
								<p>
									Alle Preise sind in Schweizer Franken (CHF)
									und inkl. MWST angegeben. Änderungen der auf
									der Website (www.rj-stamp.ch) angegebenen
									Preise und Konditionen sind vorbehalten. Als
									Stichtag gilt jedoch der Tag der Bestellung.
									Anspruch auf eine nachträgliche
									Preisanpassung oder Gutschrift besteht
									nicht.{" "}
								</p>
								<div />
								<h3>Versandkosten innerhalb der Schweiz</h3>
								<ul>
									<li>
										bis CHF 300.– Bestellwert: CHF 5.00
										(inkl. Einschreiben)
									</li>
									<li>
										ab CHF 300.– Bestellwert: CHF 0.00
										(inkl. Einschreiben)
									</li>
									<li>Pakete?</li>
								</ul>
								<div />
								<h3>
									Versandkosten innerhalb Europa und Übersee
								</h3>
								<ul>
									<li>
										bis CHF 300.– Bestellwert: CHF 10.00
										(inkl. Einschreiben)
									</li>
									<li>
										ab CHF 300.– Bestellwert: CHF 0.00
										(inkl. Einschreiben)
									</li>
									<li>Pakete?</li>
								</ul>
								<div />
								<p>
									Ab einem Bestellwert von 100.– wird die
									Sendung durch die R&amp;J universal AG per
									«Einschreiben» verschickt und ist durch die
									R&amp;J universal AG zum Warenwert
									versichert.
								</p>
								<p>
									Versandaufträge in Länder, welche spezielle
									Versandbestimmungen oder Sanktionen gegen
									die Einfuhr von bestimmten Waren
									herausgegeben haben, können von der R&amp;J
									universal AG nicht beliefert werden. Der
									Käufer ist selbst für die Beachtung von
									ausländischen Zoll- und Devisenvorschriften
									usw. verantwortlich. Die R&amp;J universal
									AG lehnt die Verantwortung für allfällige
									Folgen, die aus Widerhandlungen solcher
									Bestimmungen entstehen können, ausdrücklich
									ab.
								</p>
								<hr />
								<h2>Zahlung &amp; Eigentumsvorbehalt</h2>
								<p>
									Im Online-Shop der R&amp;J universal AG gilt
									die Zahlart per Vorkasse. Die Rechnung
									können Sie mittels Banküberweisung oder mit
									Pay Pal begleichen. Gebühren und Kosten, die
									durch eine nationale oder internationale
									Geldtransaktion entstanden sind, können
									nicht auf die R&amp;J universal AG
									übertragen werden. Allfällige
									Transaktionskosten gehen immer zu Lasten des
									Auftraggebers.
								</p>
								<p>
									Die bestellten und gelieferten Produkte
									bleiben bis zur vollständigen Bezahlung im
									Eigentum der R&amp;J universal AG.
								</p>
								<hr />
								<h2>Lieferung</h2>
								<p>
									Im Online-Shop der R&amp;J universal AG
									erfolgt der Versand grundsätzlich auf
									Vorkasse. Die Lieferung erfolgt direkt an
									die vom Käufer bekannt gegebene
									Lieferadresse und Kontaktperson.{" "}
								</p>
								<p>
									Die angegebenen Lieferfristen werden in
									Werktagen angegeben, darunter verstehen sich
									alle Wochentage mit Ausnahme von Samstagen,
									Sonntagen und allgemeinen oder gesetzlichen
									Feiertagen.
								</p>
								<p>
									Die Lieferung erfolgt unter der Bedingung
									der rechtzeitigen und ordnungsgemässen
									Belieferung durch unsere Lieferanten. Bei
									höherer Gewalt wie Streik, sonstige
									Arbeitskampfmassnahmen, Krieg,
									Naturkatastrophen oder andere von uns nicht
									beeinflussbare Umstände, übernehmen wir
									keine Haftung. Zudem haften wir auch nicht
									für Lieferverzögerungen, welche durch unsere
									Lieferanten oder durch Dritte verursacht
									wurden.
								</p>
								<p>
									Angebote gelten so lange, wie sie auf
									unserer Internetseite als zum Verkauf
									gekennzeichnet veröffentlicht werden. Alle
									Angaben sind ohne Gewähr. Insbesondere wird
									für die Verfügbarkeit der Artikel sowie die
									angegebenen Preise kein Anspruch gegeben.
									Irrtümer, Druckfehler, Preis- und
									Produktänderungen bleiben vorbehalten.
								</p>
								<hr />
								<h2>Prüfpflicht &amp; Reklamationen</h2>
								<p>
									Die durch die R&amp;J universal AG zum
									Verkauf stehenden Produkte sind gewissenhaft
									und sorgfältig beschrieben und geprüft.
									Falls Sie trotzdem einen Grund zur Rückgabe
									haben sollten, beachten Sie bitte folgende
									Regelungen.
								</p>
								<p>
									Der Käufer hat die gelieferten oder
									abgeholten Produkte sofort auf Richtigkeit,
									Vollständigkeit und Lieferschäden zu prüfen.
									Allfällige Lieferschäden, Falsch- und
									unvollständige Lieferungen sind innerhalb
									von 5 Kalendertagen (ab dem Zeitpunkt der
									Zustellung) an die R&amp;J universal AG zu
									melden.{" "}
								</p>
								<p>
									Beschädigte oder falsche Produkte muss der
									Käufer anschliessend, in unverändertem
									Zustand in der Originalverpackung innerhalb
									von 14 Kalendertagen (ab dem Zeitpunkt der
									Zustellung) mit der Originalrechnung zur
									Gutschrift (oder falls möglich zum Umtausch)
									zurückgeben. Ansonsten kann die Reklamation
									durch die R&amp;J Universal AG abgewiesen
									werden.
								</p>
								<p>
									Falls Sie Marken zu einem Prüfer schicken
									wollen, muss dies der R&amp;J universal AG
									vor dem Kauf schriftlich mitgeteilt werden.
									Nach Bestätigung durch uns verlängert sich
									die Reklamationsfrist bis zur Rückgabe durch
									den Prüfer.
								</p>
								<hr />
								<h2>Widerrufsrecht</h2>
								<p>
									Innerhalb von 14 Kalendertagen hat der Kunde
									das Recht, die Bestellung schriftlich zu
									widerrufen. Die Frist beginnt mit der durch
									den Kunden getätigten Bestellung der Ware.
									Der Beweis des Widerrufs obliegt dem Kunden.
									Die Kosten einer allfälligen Rücksendung der
									Ware trägt der Kunde.
								</p>
								<p>
									Um das Widerrufsrecht auszuüben, muss der
									Entschluss, den Vertrag gegenüber der
									R&amp;J universal AG zu widerrufen, mittels
									einer eindeutigen Erklärung (z.B. einer
									E-Mail oder einem per Post versandten Brief)
									an uns mitgeteilt werden. Verwenden Sie
									dafür folgende Kontaktdaten:{" "}
								</p>
								<p>
									R&amp;J universal AG
									<br />
									Bösch 41
									<br />
									6331 Hünenberg
									<br />
									Schweiz
								</p>
								<p>E-Mail: info@rj-stamp.ch</p>
								<hr />
								<h2>Schadenersatz</h2>
								<p>
									Wegen Fehlern in Texten, Preisen und
									Abbildungen oder wegen verspäteter und/oder
									unterbliebener Lieferung bleiben
									Schadenersatzansprüche strikt
									ausgeschlossen. Die Farbe der im Online Shop
									abgebildeten Produkte kann z.B. aufgrund
									Ihres Computer-Bildschirms von der
									tatsächlichen Produktefarbe abweichen.
								</p>
								<hr />
								<h2>Anwendbares Recht / Gerichtsstand</h2>
								<p>
									Es gilt ausschliesslich das Schweizerische
									Recht. Gerichtsstand sind die ordentlichen
									Gerichte am Sitz der R&amp;J universal AG in
									6331 Hünenberg, Schweiz.
								</p>
								<hr />
								<h2>Haftung</h2>
								<p>
									Alle Fälle von Vertragsverletzungen und
									deren Rechtsfolgen sowie alle Ansprüche des
									Kunden, gleichgültig aus welchem Rechtsgrund
									sie gestellt werden, sind in diesen AGB
									geregelt. Andere Ansprüche des Käufers –
									unabhängig vom Rechtsgrund – sind, soweit
									gesetzlich zulässig, ausgeschlossen. Die
									R&amp;J universeal AG, Ihre Hilfspersonen
									und allfällige Erfüllungsgehilfen haften
									nicht für Schäden, die nicht an der Ware
									selbst entstanden sind, insbesondere nicht
									für Mangelfolgeschäden, entgangenen Gewinn
									oder sonstige Vermögensschäden des Käufers.
								</p>
								<hr />
								<h2>Datenschutz</h2>
								<p>
									Es gelten die aktuellen gesetzlichen
									Datenschutzbestimmungen. Details dazu
									entnehmen Sie unserer Seite mit der
									Datenschutzerklärung.
								</p>
								<hr />
								<h2>
									Salvatorische Klausel &amp;
									Änderungsvorbehalt
								</h2>
								<p>
									Sollten einzelne Bestimmungen oder Inhalte
									dieser AGB unwirksam oder unvollständig
									sein, so wird dadurch die Wirksamkeit der
									AGB im Übrigen nicht berührt. Die
									Vertragsparteien werden die unwirksame
									Bestimmung durch eine wirksame Bestimmung
									ersetzen, die dem gewollten wirtschaftlichen
									Zweck der ungültigen Bestimmung sowie dem
									ursprünglich vereinbarten
									Vertragsgleichgewicht möglichst nahe kommt.
									Dasselbe gilt auch für allfällige
									Vertragslücken.
								</p>
								<p>
									Die R&amp;J universal AG ist dazu
									berechtigt, die vorliegenden AGB und die
									dazugehörenden Preislisten jederzeit zu
									ändern.
								</p>
								<hr />
								<h2>Kontakt</h2>
								<p>
									R&amp;J universal AG
									<br />
									Bösch 41
									<br />
									6331 Hünenberg
									<br />
									Schweiz
								</p>
								<p>
									Telefon: +41 44 777 88 22
									<br />
									E-Mail: info@rj-stamp.ch
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default AGB;
