import { Language } from "../types/Language";

export function nr(language: Language) {
	switch (language) {
		case "de":
			return 'Nr.'
		case "fr":
			return 'Non.'
		default:
			return 'Nr.'
	}
}

export function color(language: Language) {
	switch (language) {
		case "de":
			return 'Farbe'
		case "fr":
			return 'Couleur'
		default:
			return 'Farbe'
	}
}

export function rp(language: Language) {
	switch (language) {
		case "de":
			return 'Rp.'
		case "fr":
			return 'Rp.'
		default:
			return 'Rp.'
	}
}

export function description(language: Language) {
	switch (language) {
		case "de":
			return 'Beschrieb'
		case "fr":
			return 'Décrit'
		default:
			return 'Beschrieb'
	}
}
