import Observer from "../observerstore/Observer";
import styleDate from "../functions/styleDate";
import React from "react";
import * as T from "../language/AdditionalInformations";
import { Language } from "../types/Language";
import ParentProduct from "../models/ParentProduct";
import Store from "../observerstore/Store";
const $ = Store.data;

interface Props {
	product: ParentProduct;
}
interface State {
	price: boolean;
	detail: boolean;
	language: Language;
}

export default class AdditionalInformations extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			price: false,
			detail: false,
			language: $.language,
		};
	}

	update() {
		this.setStateNStore({
			language: $.language,
		});
	}

	render() {
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				{this.props.product.gueltigkeitsdauer +
					this.props.product.zusatzinfo_1 !==
					"" && <hr className="zero-line" />}
				{this.props.product.gueltigkeitsdauer !== "" && (
					<p>
						<i className="grey">{T.validTo(this.state.language)}</i>{" "}
						{styleDate(
							this.state.language,
							this.props.product.gueltigkeitsdauer
						)}
					</p>
				)}
				{this.props.product.zusatzinfo_1 !== "" && (
					<p>{this.props.product.zusatzinfo_1}</p>
				)}
			</>
		);
	}
}
