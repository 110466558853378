import Letter from "../models/Letter";

export default class LetterCollection {
	collection: Letter[] = [];

	constructor() {
		this.collection = [];
	}

	translateApi(json: any) {
		if(json !== undefined) {
			this.collection = json.map((p: any) => {
				let letter: Letter = new Letter();
				letter.translateApi(p);
				return letter;
			});
		}
	}

	all(): Letter[] {
		return this.collection;
	}
}
