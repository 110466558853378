import { REQUESTURL } from "../constants/urls";
import Product from "../models/Product";

class ProductCollection {
	collection: Product[];
	count: number;
	localStorageKey: string;

	constructor() {
		this.collection = [];
		this.count = 0;
		this.localStorageKey = "token";
	}

	getSelectionCount(): number {
		return this.collection.filter((p) => p.selected === true).length;
	}

	getSelectedProducts(): Product[] {
		return this.collection.filter((product) => product.selected === true);
	}

	getSelectedProduct(): Product | undefined {
		return this.collection.find((product) => product.selected === true);
	}

	unselectAll() {
		for (let i in this.collection) {
			this.collection[i].selected = false;
		}
	}

	selectAll() {
		for (let i in this.collection) {
			this.collection[i].selected = true;
		}
	}

	selectToggle(id: number) {
		let product: Product | undefined = this.getProductById(id);
		if (product !== undefined) {
			product.selected = !product.selected;
		}
	}

	getProductById(id: number): Product | undefined {
		return this.collection.find((p) => p.id === id);
	}

	getTitle(): string {
		return this.collection[0].name_de;
	}

	getDescription(): string {
		return (
			this.collection[0].name_de +
			", " +
			this.collection[0].langbeschrieb_de
		);
	}

	getYear(): string {
		let output: string = "";
		let years: string[] = [];
		for (let i in this.collection) {
			years.push(this.collection[i].jahr);
		}
		years.sort();
		let minYear: string = years[0];
		let maxYear: string = years[years.length - 1];

		if (minYear === maxYear) {
			output = minYear;
		} else {
			output = minYear + "–" + maxYear;
		}
		return output;
	}

	readApi(Store: any, suchbegriff: string, kat_id: number) {
		const _this = this;
		const url = `${REQUESTURL}products/${suchbegriff}/${kat_id}`;

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then(function (response) {
				if (response.ok) return response.json();
				throw new Error("Irgendwas konnte nicht geladen werden");
			})
			.then(function (json) {
				_this.translateApi(json);
				Store.notify();
			})
			.catch(function (err) {
				console.log(err);
			});
	}

	true_or_false(input: any) {
		if (Number(input) === 0 || input === null) {
			return false;
		}
		return true;
	}

	stringCheck(input: any): string {
		let output: string = String(input);
		return output !== "null" ? output : "";
	}

	translateApi(json: any) {
		this.collection = json.map((p: any) => {
			let product: Product = new Product();
			product.translateApi(p);
			return product;
		});
	}

	searchId(id: number) {
		return this.collection.find((p) => p.id === id);
	}

	all() {
		return this.collection;
	}
}

export default ProductCollection;
