
import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";

export default class Dank extends Component {
	render() {
		return (
			<>
				<Container>
					<code className="component_info">
						{this.constructor.name}
					</code>
					<Row>
						<Col sm={7}>
							<div className="main">
								<h1>Dank</h1>
								<hr />
								<p>Wir bedanken uns bei folgenden Personen für die Bereitstellung von Briefmarken:
									</p>
								<Table striped bordered hover style={{marginBottom: '50px'}}>
									<thead>
										<tr>
											<th>Name</th>
											<th>Sonderausgabe/Blocks</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Hans Meier</td>
											<td>Automatenmarken</td>
										</tr>
										<tr>
											<td>Hans Dampf</td>
											<td>Portomarken</td>
										</tr>
										<tr>
											<td>Irgendwas GmbH</td>
											<td>Telegraphenmarken</td>
										</tr>
										<tr>
											<td>Hans Muster</td>
											<td>Kochermarken</td>
										</tr>
									</tbody>
								</Table>
								<br />
							</div>
						</Col>
						<Col sm={5}>
							<div className="main sidebar-right">
								<h1>Helfer werden</h1>
								<hr />
								<p>
								Sie sind im Besitz von Briefmarken aus einer hier noch nicht vollständigen Kateogrie und wollen sich am Aufbau dieser Website beteiligen?
								Treten Sie mit uns in Kontakt unter E-Mail
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}
