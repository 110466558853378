import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class Footer extends Component {
	scrollTop() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	render() {
		// console.log(this.props.match.url);
		return (
			<>
				<div id="footer">
					<code className="component_info">
						{this.constructor.name}
					</code>
					<Container>
						<Row>
							<Col sm={3}>
								<a
									href="#/datenschutz"
									onClick={this.scrollTop}
								>
									Datenschutz
								</a>
								<br />
								<a href="#/impressum" onClick={this.scrollTop}>
									Impressum
								</a>
								<br />
							</Col>
							<Col sm={3}>
								<a href="#/agb" onClick={this.scrollTop}>
									AGB
								</a>
								<br />
							</Col>
							<Col sm={3}>
								<a href="#/sponsoring" onClick={this.scrollTop}>
									Spenden
								</a>
								<br />
								<a href="#/dank" onClick={this.scrollTop}>
									Dank
								</a>
								<br />
							</Col>
							<Col sm={3} className="text-right">
								<span className="gold">
									{"© " +
										new Date().getFullYear() +
										" | R&J universal AG"}
								</span>
								<br />
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}
