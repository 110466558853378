import React from "react";
import Observer from "../observerstore/Observer";
import CategoryCollection from "../models/CategoryCollection";
import Store from "../observerstore/Store";
const $ = Store.data;

interface Props {
	activeCategory: number;
}

interface State {
	categoryCollection: CategoryCollection;
}

export default class Sidebar extends Observer<Props, State> {
	//notAllowedCategories: number[] = [57, 58, 61, 62, 75, 78, 79];
	notAllowedCategories: number[] = [];

	constructor(props: Props) {
		super(props);
		this.state = {
			categoryCollection: $.categoryCollection,
		};
	}

	componentDidMount() {
		Store.attach(this);
		$.categoryCollection.readApi(Store);
	}

	update() {
		this.setStateNStore({
			categoryCollection: $.categoryCollection,
		});
	}

	click() {}

	topFunction() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	render() {
		let active = this.props.activeCategory;
		return (
			<>
				<code className="component_info">{this.constructor.name}</code>
				<ul id="navigation-list">
					<a href={"#/overview/0/15/1"}>
						<li>Schweiz</li>
					</a>
					<ul>
						{this.state.categoryCollection.collection.map(
							(p, i) => {
								if (this.notAllowedCategories.includes(p.id)) {
									return <li key={i}>{p.level_2}</li>;
								} else {
									return (
										<a
											href={
												"#/overview/" + p.id + "/15/1"
											}
											key={i}
										>
											<li
												className={
													"" +
													(active === p.id
														? "active"
														: "")
												}
											>
												{p.level_2}
											</li>
										</a>
									);
								}
							}
						)}
					</ul>
				</ul>
			</>
		);
	}
}
