import ParentProduct from "../models/ParentProduct";
import { APIURL } from "../constants/urls";
import Picture from "../interfaces/Picture";

export default class Color extends ParentProduct {
	color: string = "";
	color_beschrieb: string = "";
	color_pictures: Picture[] = [
		{
			original: `${APIURL}/image/referenzdatenbank/default.jpg`,
			thumbnail: `${APIURL}/image/referenzdatenbank/default.jpg`,
			small: `${APIURL}/image/referenzdatenbank/default.jpg`,
			medium: `${APIURL}/image/referenzdatenbank/default.jpg`,
			large: `${APIURL}/image/referenzdatenbank/default.jpg`,
		},
	];

	public async translateApi(json: any): Promise<void> {
		super.translateApi(json);
		this.color = json.pro_znr_farbvariante;
		this.color_beschrieb = json.pro_farbe;
		await this.getPictures();
	}

	public async getPictures(): Promise<void> {
		let requestName = this.getRequestName();
		let response: any = await fetch(
			"https://api.rj-shop.ch/picturePath/" + requestName,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		if (response.ok) {
			let json: any = await response.json();
			if (json.length !== 0) {
				this.color_pictures = [];
				for (let i of json) {
					this.color_pictures.push(this.toPicturePath(i));
				}
			}
		} else {
			throw new Error("Irgendwas konnte nicht geladen werden");
		}
	}

	public getRequestName(): string {
		let znr = this.cleanZnr(this.znr);
		let hauser_zusatz = this.hauser_zusatz;
		let color = this.cleanColorName(this.color);
		return hauser_zusatz + "_" + znr + "_" + color;
	}

	public getDirectoryName(): string {
		let znr = this.cleanZnr(this.znr);
		let hauser_zusatz = this.hauser_zusatz;
		let color = this.cleanColorName(this.color);
		return "CHE_" + hauser_zusatz + "_" + znr + "_" + color;
	}

	public toPicturePath(input: string): Picture {
		let directory = this.getDirectoryName();
		let file = input;
		return super.toPicturePath(directory, file);
	}

	public cleanColorName(input: string): string {
		let output: string = input;
		output = output.replaceAll(". ", "-");
		return output;
	}
}
