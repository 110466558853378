import { Language } from "../types/Language";

export function info(
	language: Language,
	searchTerm: string,
	title: string,
	count: number
): string {
	switch (language) {
		case "de":
			return `Suchbegriff «${searchTerm}» gefunden in «${title}»: ${count} Treffer.`;
		case "fr":
			return `Terme de recherche «${searchTerm}» trouvé dans «${title}»: ${count} résultats.`;
		default:
			return `Suchbegriff «${searchTerm}» gefunden in «${title}»: ${count} Treffer.`;
	}
}

export function resetSearch(language: Language): string {
	switch (language) {
		case "de":
			return "Suche zurücksetzen.";
		case "fr":
			return "Réinitialiser la recherche.";
		default:
			return "Suche zurücksetzen.";
	}
}
