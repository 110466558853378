import {months} from '../language/months'
import { Language } from "../types/Language";

export default function styleDate(language: Language, date: string): string {

	if (date !== "") {
		const dateParts: string[] = date.split(".");
		if (dateParts.length === 3) {
			// Style Day
			let day = String(Number(dateParts[0]));

			// Style Month
			let index = Number(dateParts[1]) - 1
			let month = months[index][language];

			return day + ". " + month + " " + dateParts[2];
		}
	}

	return "–";
}
