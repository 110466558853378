import Product from "./Product";

class ShoppingCart {

	cart: Product[];

	constructor() {
		this.cart = []
	}

	getCartPrice() {
		let temp: number = 0
		for (let i in this.cart) {
			temp = temp + Number(this.cart[i].preis)
		}
		return String(temp.toFixed(2))
	}

	add(product: Product) {
		let temp: Product | undefined = this.cart.find(p => p.id ===  product.id);
		if(temp === undefined) {
			this.cart.push(product)	
		}
		this.updateLocalStorage();
	}

	remove(id: number) {
		// TODO: Muss noch implementiert werden
		this.cart = this.cart.filter(function( product ) {
			return product.id !== id;
		});
		this.updateLocalStorage();
	}

	clear() {
		this.cart = [];	
	}

	updateLocalStorage() {
		localStorage.setItem("shoppingCart", JSON.stringify(this.cart));
		//this.updateObservers();
	}

	deleteLocalStorage() {
		localStorage.removeItem("shoppingCart");
	}

	readLocalStorage(Store: any) {
		let storageObject: any = localStorage.getItem("shoppingCart");
		if (!(storageObject === null)) {
			storageObject = JSON.parse(storageObject);
			let output: Product[] = [];

			// Produkt abfüllen
			this.cart = output;
		} 
		Store.notify();
	}
}

export default ShoppingCart;

