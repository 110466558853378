import Observer from "../observerstore/Observer";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import DropdownButton from "react-bootstrap/DropdownButton";
import * as T from "../language/searchbar.json";
import { Language } from "../types/Language";
import React from "react";
import Store from "../observerstore/Store";
const $ = Store.data;

interface Props {}
interface State {
	searchType: string;
	searchTerm: string;
	categoryTitle: string;
	language: Language;
}

export default class SearchBar extends Observer<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			searchType: "all",
			searchTerm: "",
			categoryTitle: "",
			language: $.language,
		};
		this.click = this.click.bind(this);
		this.search = this.search.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleKeypress = this.handleKeypress.bind(this);
	}

	update() {
		this.setStateNStore({
			searchType: $.url.searchType,
			searchTerm: $.url.searchTerm,
			categoryTitle: $.categoryCollection.findTitle($.url.katId),
			language: $.language,
		});
	}

	handleChange(event: any) {
		this.setState({ searchTerm: event.target.value });
	}

	handleKeypress(e: any) {
		if (e.key === "Enter") this.search();
	}

	search() {
		let searchTerm: string = this.state.searchTerm;
		$.url.searchTerm = searchTerm;
		$.groupCollection.readApi(
			Store,
			$.url.categories,
			$.url.anzahl,
			1,
			$.url.sortAttribute,
			$.url.sortOrder,
			$.url.searchTerm,
			$.url.searchType
		);
		this.firstPageJump();
	}

	firstPageJump() {
		let category: string = String($.url.categories[0]);
		if ($.url.categories.length > 1) {
			category = "0";
		}
		let url: string = "#/overview/";
		url += category + "/";
		url += $.url.anzahl + "/";
		url += 1;
		window.location.href = url;
	}

	removeFilter() {
		let url = "#/overview/0/" + $.url.anzahl + "/1";
		window.location.href = url;
	}

	click(e: any) {
		$.url.searchType = e.target.id;
		Store.notify();
	}

	render() {
		let mappingValueText: any = {
			number: T.nummer[this.state.language],
			year: T.jahr[this.state.language],
			all: T.volltext[this.state.language]
		}
		return (
			<React.Fragment>
				<code className="component_info">{this.constructor.name}</code>

				<InputGroup className="mb-3" size="sm">
					<DropdownButton
						as={InputGroup.Prepend}
						variant="outline-primary"
						title={mappingValueText[this.state.searchType]}
						id="searchbar-dropdown"
					>
						<Dropdown.Item
							href="#"
							id="number"
							onClick={this.click}
						>
							{T.nummer[this.state.language]}
						</Dropdown.Item>
						<Dropdown.Item href="#" id="year" onClick={this.click}>
							{T.jahr[this.state.language]}
						</Dropdown.Item>
						<Dropdown.Item href="#" id="all" onClick={this.click}>
							{T.volltext[this.state.language]}
						</Dropdown.Item>
					</DropdownButton>
					{this.state.categoryTitle !== "Schweiz" && (
						<InputGroup.Prepend>
							<Button
								variant="primary"
								id="searchbar-category"
								disabled
							>
								{this.state.categoryTitle}
							</Button>
							<Button
								variant="primary"
								onClick={this.removeFilter}
							>
								&#215;
							</Button>
						</InputGroup.Prepend>
					)}
					<FormControl
						value={this.state.searchTerm}
						onChange={this.handleChange}
						onKeyPress={this.handleKeypress}
						type="text"
						placeholder={T.suchbegriffEingeben[this.state.language]}
					/>
					<InputGroup.Append>
						<Button
							variant="outline-primary"
							onClick={this.search}
							style={{ height: "31px", paddingTop: "3px" }}
						>
							<span className="material-icons">search</span>
						</Button>
					</InputGroup.Append>
				</InputGroup>
			</React.Fragment>
		);
	}
}
